.popup__content {
  background-color: #fff;
  position: relative;
  margin: 0px auto;
  width: 430px;
  padding-bottom: 36.51px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 550px) {
  .popup__content {
    max-width: 282px;
  }
}
