.profile__edit-button {
  min-width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url("../../../images/Edit-Button.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 18px;
  cursor: pointer;
}

.profile__edit-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 550px) {
  .profile__edit-button {
    min-width: 18px;
    height: 18px;
  }
}
