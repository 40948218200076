.header {
  margin: 0 auto;
  padding-bottom: 41px;
  border-bottom: 1px solid #545454b3;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  min-width: 880px;
}

@media screen and (max-width: 880px) {
  .header {
    max-width: 100%;
    min-width: 0;
  }
}
