.profile {
  margin: 36.25px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 880px) {
  .profile {
    flex-direction: column;
    justify-content: center;
  }
}
