.profile__name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 auto;
  padding: 0;
  font-size: 42px;
  line-height: 48px;
  font-weight: 500;
}

@media screen and (max-width: 880px) {
  .profile__name {
    margin-top: 26.25px;
    text-align: center;
  }
}
