.popup__image {
  max-width: 75vw;
  width: 100%;
  height: 75vh;
  max-height: auto;
  position: relative;
  margin: 0 auto;
  display: block;
  align-self: center;
  justify-self: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 550px) {
  .popup__image {
    max-width: 240px;
    max-height: 300px;
  }
}
