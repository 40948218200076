.page {
  background: #000000;
  display: flex;
  flex-direction: column;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  min-width: 320px;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}
