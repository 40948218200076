.profile__value_type_name {
  min-width: 373px;
  font-size: 42px;
  line-height: 48px;
  font-weight: 500;
}

@media screen and (max-width: 880px) {
  .profile__value_type_name {
    min-width: 196px;
  }
}

@media screen and (max-width: 550px) {
  .profile__value_type_name {
    font-size: 22px;
    line-height: 26.63px;
  }
}
