.popup__form {
  display: flex;
  flex-direction: column;
  max-width: 358px;
  padding-top: 48px;
  width: 100%;
  margin: 0 auto;
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 550px) {
  .popup__form {
    max-width: 238px;
  }
}
