.menu__button {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #a9a9a9;
  margin: 0 0 0 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
