.profile__add-button {
  width: 150px;
  height: 50px;
  background-image: url("../../../images/Vector-Button.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  align-self: center;
  margin: 0;
  cursor: pointer;
}

.profile__add-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 880px) {
  .profile__add-button {
    max-width: 282px;
    width: 100%;
    margin-top: 36px;
  }
}
