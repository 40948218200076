.footer {
  font-size: 18px;
  line-height: 21.78px;
  margin: 68px auto 60px 0;
}

@media screen and (max-width: 880px) {
  .footer {
    font-size: 14px;
    line-height: 16.94px;
    min-width: 282px;
    margin: 50px auto 36px auto;
  }
}
