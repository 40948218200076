.popup__error {
  visibility: hidden;
  display: none;
  color: #ff0000;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.popup__error_visible {
  visibility: visible;
  display: block;
}
