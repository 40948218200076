.card__name {
  color: #000000;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  padding: 0;
  margin: 0 0 0 21px;
  white-space: nowrap;
  overflow: hidden;
  background: #fff;
  text-overflow: ellipsis;
}
