.popup__title {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  max-width: 330px;
  margin: 34px auto 0 36px;
  text-align: left;
  padding: 0;
}

@media screen and (max-width: 550px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin: 25px auto 0 22px;
    min-width: 238px;
  }
}
