.popup__image-wrap {
  width: 100%;
  max-width: 75vw;
  margin: 0 auto;
  height: auto;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
