.places__cards {
  list-style-type: none;
  width: 100%;
  display: grid;
  max-width: 880px;
  min-width: 282px;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 17px;
  row-gap: 20px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 880px) {
  .places__cards {
    grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
    max-width: 600px;
  }
}

@media screen and (max-width: 620px) {
  .places__cards {
    grid-template-columns: 1fr;
  }
}
