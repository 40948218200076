.profile__info {
  max-width: 415px;
}

@media screen and (max-width: 880px) {
  .profile__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
