.card__delete-button {
  margin-top: 18px;
  margin-left: 249px;
  float: right;
  width: 18px;
  height: 19.3px;
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  background-image: url("../../../images/Trash.svg");
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}

.card__delete-button:hover {
  opacity: 0.5;
}

.card__delete-button_hidden {
  visibility: hidden;
}
