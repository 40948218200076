.card__like_active {
  background-image: url("../../../images/Heart-active.svg");
  opacity: 1;
  display: flex;
  width: 21px;
  height: 19px;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}
