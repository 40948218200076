.welcome-page__button {
    margin-top: 216px;
    width: 358px;
    height: 50px;
    cursor: pointer;
    background-color: #fff;
}

.welcome-page__button:hover {
    opacity: 0.85;
}