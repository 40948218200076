.profile__container {
  margin-right: auto;
  margin-left: 30px;
}

@media screen and (max-width: 880px) {
  .profile__container {
    margin: 0 auto;
  }
}
