.card__box {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0;
  align-items: center;
  border-radius: 0 0 10px 10px;
  max-height: 79px;
}
